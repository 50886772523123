<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The rate constant,
        <stemble-latex content="$\text{k,}$" />
        for the reaction described below is
        <stemble-latex content="$4.53\times 10^{-3}\text{ M}^{-1}\text{s}^{-1}$" />
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="C2H4Br2(aq) + 3I-(aq) -> C2H4(g) + 2Br-(aq) + I_3^-(aq)" />
        <stemble-latex content="$\qquad\text{rate=k[}\ce{C2H4Br2}\text{][I}^-]$" />
      </p>

      <p class="mb-3">
        Based on the information provided above, answer each of the questions below:
      </p>

      <v-simple-table style="width: 600px" class="mb-5 pl-8">
        <thead>
          <tr>
            <th>
              <stemble-latex content="$\text{Trial}$" />
            </th>
            <th style="text-align: center">
              <chemical-latex content="[C2H4Br2]_0" />
              <stemble-latex content="$\text{(M)}$" />
            </th>
            <th style="text-align: center">
              <chemical-latex content="[I-]_0" />
              <stemble-latex content="$\text{(M)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex content="$\text{Initial rate of}$" />
              <chemical-latex content="C2H4" />
              <stemble-latex content="$\text{formation (M)}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$1$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$x$" />
            </td>
            <td style="text-align: center">
              <number-value :value="I1" />
            </td>
            <td style="text-align: center">
              <number-value :value="rate1" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$2$" />
            </td>
            <td style="text-align: center">
              <number-value :value="CHBr2" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$y$" />
            </td>
            <td style="text-align: center">
              <number-value :value="rate2" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$3$" />
            </td>
            <td style="text-align: center">
              <number-value :value="CHBr3" />
            </td>
            <td style="text-align: center">
              <number-value :value="I3" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$z$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">a) What is the value of x?</p>
      <calculation-input
        v-model="inputs.input1"
        prepend-text="$x:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-2">b) What is the value of y?</p>
      <calculation-input
        v-model="inputs.input2"
        prepend-text="$y:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-2">c) What is the value of z?</p>
      <calculation-input
        v-model="inputs.input3"
        prepend-text="$z:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question87',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    I1() {
      return this.taskState.getValueBySymbol('I1').content;
    },
    rate1() {
      return this.taskState.getValueBySymbol('rate1').content;
    },
    CHBr2() {
      return this.taskState.getValueBySymbol('CHBr2').content;
    },
    rate2() {
      return this.taskState.getValueBySymbol('rate2').content;
    },
    CHBr3() {
      return this.taskState.getValueBySymbol('CHBr3').content;
    },
    I3() {
      return this.taskState.getValueBySymbol('I3').content;
    },
  },
};
</script>
